import store from "@/store";

const st = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'BB', 'NB', 'DB', 'CB', 'XB'];

class Utils {

    covertFileSize(size: number, index: number = 0): string {
        let tempSize = size / 1024;
        if (tempSize < 1024) return `${tempSize.toFixed(2)}${st[index]}`;
        return this.covertFileSize(tempSize, (index + 1));
    }

    /**
     * 验证权限
     */
    vm(k: number): boolean {
        let info = sessionStorage.getItem(store.getters.info);
        if (info) {
            let infoObj: any = JSON.parse(info);
            let currentModules: Array<number> = infoObj.modules;
            return currentModules.some((j: number) => j === k);
        }
        return false;
    }

}

const c = new Utils();

export {c as Utils}
