







import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import {Utils} from "@/common/Utils";

@Component
export default class el_limit extends Vue {
    @Prop() value!: number;
    show: boolean = false;
    created() {
        this.show = Utils.vm(this.value);
    }
}

